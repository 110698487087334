import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom'
import Navmenu from './NavMenu';
import { ButtonContent } from "../../global/boton/ButtonContent";




const HeaderOne = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div>
            <div className="w-full flex justify-center bggrad p-2">
                {
                    rpdata?.simpleWidgets?.[3]?.activo ?
                        <div className='pt-10 flex justify-center items-center'>
                            {/* logo */}
                            <div className='w-[70%] md:w-[20%] pr-3 md:pr-0'>
                                <Link to={'/'}>
                                    <img
                                        src={`${rpdata?.dbPrincipal?.logo}`}
                                        alt='no found'
                                        className='w-full'
                                    />
                                </Link>
                            </div>
                        </div>
                        :
                        <div className='w-[80%] z-10 pt-3 flex justify-between items-center bg-white lg:rounded-2xl rounded-t-2xl shadow-lg -mb-24 mt-5'>
                            {/* logo */}
                            <div className='w-[80%] md:w-[23%] pr-3 md:pr-0'>
                                <Link to={'/'}>
                                    <img
                                        src={`${rpdata?.dbPrincipal?.logo}`}
                                        alt='no found'
                                        className='w-full'
                                    />
                                </Link>
                            </div>
                            {/* nav menu */}
                            <nav className='w-[20%] md:w-[80%] flex justify-around md:pl-20 pl-0'>
                                <div className='relative flex'>
                                    <Navmenu />

                                </div>
                                <div className='w-auto h-full -mt-5 hidden md:flex'>
                                    <ButtonContent btnStyle={'three'} btnphone={rpdata?.dbPrincipal?.phones?.[0].phone} />
                                </div>
                            </nav>
                        </div>
                }
            </div>

        </div>
    )
}

export default HeaderOne